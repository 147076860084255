import React, { useContext } from "react";
import { StoreContext } from "../context/StoreContext";
import { motion, AnimatePresence } from "framer-motion";

export default function Loader(props) {
  const { isLoading } = useContext(StoreContext);

	return (
    <>
      <AnimatePresence>
        {isLoading && (
          <motion.div
						className="page-loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, delay: 0.25 }}
          >
            <div className="container">
              <div className="ball ball-one"></div>
              <div className="ball ball-two"></div>
              <div className="ball ball-three"></div>
              <div className="ball ball-four"></div>
              <div className="shadow shadow-one"></div>
              <div className="shadow shadow-two"></div>
              <div className="shadow shadow-three"></div>
              <div className="shadow shadow-four"></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
