import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { StoreContext } from '@context/StoreContext';
import Logo from '@images/wilder-and-weathered-short-logo.svg';
import HeaderMenu from './HeaderMenu';
import CartIcon from "@images/svg/shopping-cart.svg";
import Cart from '@components/Cart/Cart';
import Loader from '@components/Loader';
import { get } from 'lodash';

const Header = ({ siteTitle, siteDescription, categories=[] }) => {
  const { cart, toggleCartOpen} = useContext(StoreContext);
  const lineItems = get(cart, 'lineItems', []);
  const qty = lineItems.reduce((total, item) => {
    return total + item.quantity;
  }, 0)
  return (
    <>
      <header>
        <div className="cart-btn btn" onClick={toggleCartOpen}>
          <CartIcon className="icon" />
          {qty > 0 && <div className="cart-qty">{qty}</div>}
        </div>
        <HeaderMenu categories={categories}/>
        <HeaderMenu navType="desktop" categories={categories}/>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
          className="logo-link"
        >
          <Logo id="logo" alt="{siteTitle} logo" title={siteTitle} />
        </Link>
        <div className="siteDescription">{siteDescription}</div>
        <Cart />
      </header>
      <Loader />
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
