import React from 'react';
import { Link } from 'gatsby';

const menuList = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Shop',
    path: '/shop',
  },
  {
    label: 'Blog',
    path: '/blog',
  },
  {
    label: 'FAQ',
    path: '/faq',
  },
];
export default function FooterMenu() {
  return (
    <div id="footer-menu-wrapper">
      <nav className="nav" role="navigation">
        <ul id="footer-menu" className="menu">
          {menuList &&
            menuList.map((el, index) => (
              <li key={index}>
                <Link to={el.path || '/'} activeClassName="active">
                  {el.label}
                </Link>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
}
