import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm = () => {
  const [email, setEmail] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        if (data.result === 'success') {
          setShowConfirmation(true);
          setTimeout(function () {
            setShowConfirmation(false);
          }, 3000);
          setEmail('');
        }
      })
      .catch((error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <div id="subscribe-wrapper">
      <form
        onSubmit={handleSubmit}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <h2>Stay Informed</h2>
        <div>
          <input name="email" type="text" onChange={handleEmailChange} placeholder="EMAIL ADDRESS" />
          <button type="submit">Subscribe</button>
        </div>
        {showConfirmation && <p>Thank you! A confirmation email has been sent to you.</p>}
      </form>
    </div>
  );
};

export default EmailListForm;
