import React from 'react';
import { Link } from 'gatsby';
import { useToggle } from '@hooks/useToggle';
import MenuBurgerIcon from '@images/svg/bars-light.svg';
import MenuCloseIcon from '@images/svg/times-light.svg';


const renderCategoriesMenu = (categories) => {
  categories.map(category => {
    return (
      <li key={category.id}>
        <Link to={category.slug}>{category.name}</Link>
      </li>
    )
  });
}

const HeaderMenu = (props) => {
  const { navType = 'mobile', categories } = props;
  const { isToggled, toggle } = useToggle(false);
  const menuList = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Shop',
      path: '/shop',
    },
    {
      label: 'Blog',
      path: '/blog',
    },
    {
      label: 'FAQ',
      path: '/faq',
    },
  ];
  if (navType === 'mobile') {
    return (
      <nav className="global-nav">
        <div onClick={toggle} id="menu-toggle-button" aria-hidden="true">
          {isToggled ? (
            <MenuCloseIcon className="close-icon" />
          ) : (
            <MenuBurgerIcon className="burger-icon" />
          )}
        </div>
        <ul id="header-mobile-menu" className={isToggled ? "open" : ""}>
          {menuList &&
            menuList.map((el, index) => (
              <li key={index}>
                <Link to={el.path || "/"} activeClassName="active">
                  {el.label}
                </Link>
                {categories && el.label === "Blog" && (
                  <ul>
                    {categories.map((cat, index) => (
                      <li key={index}>
                        <Link to={`${cat.node.uri}`} activeClassName="active">
                          {cat.node.name}
                        </Link>
                        {cat.node.wpChildren.nodes.length > 0 && (
                          <ul>
                            {cat.node.wpChildren.nodes.map((sub_cat, index) => (
                              <li key={index}>
                                <Link
                                  to={`${sub_cat.uri}`}
                                  activeClassName="active"
                                >
                                  {sub_cat.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </nav>
    );
  }
  if (navType === 'desktop') {
    return (
      <nav className="global-nav">
        <ul id="header-desktop-menu">
          {menuList &&
            menuList.map((el, index) => (
              <li key={index}>
                <Link to={el.path || "/"} activeClassName="active">
                  {el.label}
                </Link>
                {categories && el.label === "Blog" && (
                  <div className="nav-categories-wrapper">
                    <ul>
                      {categories.map((cat, index) => (
                        <li key={index}>
                          <Link to={`${cat.node.uri}`} activeClassName="active">
                            {cat.node.name}
                          </Link>
                          {cat.node.wpChildren.nodes.length > 0 && (
                            <ul>
                              {cat.node.wpChildren.nodes.map((sub_cat, index) => (
                                <li key={index}>
                                  <Link
                                    to={`${sub_cat.uri}`}
                                    activeClassName="active"
                                  >
                                    {sub_cat.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
        </ul>
      </nav>
    );
  }
};

export default HeaderMenu;
