import React, { useContext } from "react";
import { Link } from "gatsby";
import { StoreContext } from "../../context/StoreContext";
import CloseIcon from "@images/svg/times-light.svg";
import {get} from 'lodash';
const Cart = () => {
  const {
    isCartOpen,
    checkout,
    toggleCartOpen,
    updateProductQuantity,
    removeProductFromCart,
  } = useContext(StoreContext);

  return (
    <div id="cart" className={`cart ${isCartOpen ? "open" : ""}`}>
      <div className="scrollable-content">
        <h3>Your Cart</h3> {/* close button */}
        <button className="close-button" onClick={toggleCartOpen}>
          <CloseIcon />
        </button>
        <div className="content">
          {checkout.lineItems.length === 0 && (
            <>
              <p className="cart-empty-message">Your cart is empty</p>
              <p className="cart-empty-message">
                Let's go
                <Link
                  className="gradient"
                  to={`/shop`}
                  onClick={toggleCartOpen}
                >
                  {" "}
                  shop
                </Link>
                !
              </p>
            </>
          )}
          {checkout.lineItems.map((item) => (
            <div key={item.id} className="item-block">
              <img
                src={item.variant.image.src}
                alt={item.variant.image.altText}
              />
              <div className="item-block-info">
                <h4 className="title"> {item.title}</h4>
                {item.variant.selectedOptions.map((option) => {
                  if (option.name !== "Title") {
                    return (
                      <p key={option.name} className="option">
                        <strong> {option.name}:</strong> {option.value}
                      </p>
                    );
                  }
                })}
                <p className="option">
                  <strong>Qty:</strong>{" "}
                  <select
                    name={item.name}
                    id={item.id}
                    defaultValue={item.quantity}
                    onChange={(e) => {
                      updateProductQuantity(item.id, parseInt(e.target.value));
                    }}
                  >
                    {[...Array(20)].map((_, i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </p>
                <p className="price">${item.variant.price}</p>
                <button
                  className="remove-btn btn"
                  onClick={() => removeProductFromCart(item.id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          {checkout.lineItems.length > 0 && (
            <>
              <div className="cart-total">Total: $ {checkout.totalPrice}</div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <a href={checkout.webUrl}>
                  <button className="checkout-btn btn">Checkout Now</button>
                </a>
              </div>
            </>
          )}
        </div>
        <div className="cart-info">
          <p>
            Please take a minute to read our{" "}
            <Link className="" to="/faq" onClick={() => toggleCartOpen()}>
              FAQ
            </Link>{" "}
            if you have any questions before purchasing. Shipping and discount
            codes are added at checkout.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cart;
